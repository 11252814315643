import React, { useState } from 'react';
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../utils/use-auth";
import styles from "../styles/forgotPassword.module.css";

export default function ForgotPasswordPage() {
	let history = useHistory();
	let location = useLocation();
	let auth = useAuth();

	const [partnerEmailValue, setpartnerEmailValue] = useState('');
	const [validated, setValidated] = useState(false);
	const [emailSent, setEmailSent] = useState(false);
	const [hitSubmit, setHitSubmit] = useState(false);

	const handleSubmit = (event) => {
		setHitSubmit(true)
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
		} else {
			let { from } = { from: { pathname: "/" } };
			auth.sendPasswordResetEmail(partnerEmailValue, (result) => {
				// sending reset email above
				// remove input and button when submitted, use useState
				if (result.success) {
					setEmailSent(true);
					history.replace(from);
				} else {
					setEmailSent(false);
				}
			});
		}
		setValidated(true);
	};

	const handleClick = () => {
		history.replace("login")
	};

	let errorMessage;

	if (hitSubmit && !emailSent) {
		errorMessage = <p className={styles.errorText}>Something went wrong with the information that you entered. Please try again.</p>
	}

	return (
		<div className={styles.container}>
			{auth.user ? <Redirect
				to={{
					pathname: "/",
					state: { from: location }
				}}
			/>
				:
				<div className={styles.mainContainer}>
					<div className={styles.inputContainer}>
						<h1 className={styles.header}>Password reset</h1>
						{emailSent ?
							<div className={styles.submitContainer}>
								<p className={styles.emailSentText}>Please follow the instructions in the email that we just sent to reset your password.</p>
								{/* If the your email matches one on our database */}
								<p className={styles.emailSentText}>The link in the email will expire in two hours.</p>
								<input
									className={styles.returnButton}
									type="submit"
									value="Return to login"
									onClick={handleClick}
								/>
							</div>
							:
							<>
								<form className={styles.formContainer} noValidate validated={validated} onSubmit={handleSubmit}>
									<input
										className={styles.emailInput}
										type="text"
										placeholder="email"
										value={partnerEmailValue}
										required
										onChange={(event) => setpartnerEmailValue(event.target.value)}
									/>
									{errorMessage}
									<input
										className={styles.submitButton}
										type="submit"
										value="Send reset email"
									/>
								</form>
								<p className={styles.passText} onClick={handleClick}>Return to login page</p>
							</>
						}
					</div>
					<div className={styles.imageContainer}>

					</div>
				</div>
			}
		</div>
	)
}