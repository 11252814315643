// pages/login.js
import React, { useState } from 'react';
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../utils/use-auth";
import styles from "../styles/login.module.css";

export default function LoginPage(props) {
	let history = useHistory();
	let location = useLocation();
	let auth = useAuth();

	const [partnerEmailValue, setpartnerEmailValue] = useState('');
	const [partnerPasswordValue, setpartnerPasswordValue] = useState('');

	const [errorMsg, setErrorMsg] = useState('');

	const [validated, setValidated] = useState(false);
	const [submitted, setSubmitted] = useState(false);

	const [hitSubmit, setHitSubmit] = useState(false);

	const [loginFailed, setLoginFailed] = useState(false);

	const handleSubmit = (event) => {
		setHitSubmit(true);
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) {
			setLoginFailed(true);
			event.preventDefault();
			event.stopPropagation();
		} else {
			let { from } = location.state || { from: { pathname: "/" } };
			auth.login(partnerEmailValue, partnerPasswordValue, (result) => {
				console.warn(result);
				if (result.success) {
					// If login was successful, nav to root
					history.replace(from);
				} else {
					// If login was unsuccessful, let user know (stay on this page)
					event.preventDefault();
					event.stopPropagation();
					setErrorMsg(result.msg);
					setLoginFailed(true);
				}
			});
			setSubmitted(true);
		}
		setValidated(true);
	};

	const handleClick = () => {
		history.replace("/forgotpassword")
	};

	let errorMessage;

	if (loginFailed && hitSubmit) {
		errorMessage = <p className={styles.errorText}>Something went wrong with the information that you entered. Please try again.</p>
	}

	return (
		<div className={styles.container}>
			{auth.user ? <Redirect
				to={{
					pathname: "/",
					state: { from: location }
				}}
			/>
				:
				<div className={styles.mainContainer}>
					<div className={styles.inputContainer}>
						<h1 className={styles.header}>MyBalance partner login</h1>
						<form className={styles.formContainer} noValidate validated={validated} onSubmit={handleSubmit}>
							<input
								className={styles.emailInput}
								type="text"
								placeholder="email"
								value={partnerEmailValue}
								required
								onChange={(event) => setpartnerEmailValue(event.target.value)}
							/>
							<input
								className={styles.passwordInput}
								type="password"
								placeholder="password"
								value={partnerPasswordValue}
								required
								onChange={(event) => setpartnerPasswordValue(event.target.value)}
							/>
							{errorMessage}
							<input
								className={styles.submitButton}
								type="submit"
								value="Login"
							/>
						</form>
						<p className={styles.passText} onClick={handleClick}>Forgotten password?</p>
					</div>
					<div className={styles.imageContainer}>

					</div>
				</div>
			}
		</div>
	)
}