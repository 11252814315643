import React, { useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../utils/use-auth";
import styles from "../styles/pwdreset.module.css";

export default function PasswordResetPage() {
	let history = useHistory();
	let location = useLocation();
	let auth = useAuth();

	const [partnerPasswordValue, setPartnerPasswordValue] = React.useState('');
	const [partnerPasswordConfirmValue, setPartnerPasswordConfirmValue] = React.useState('');
	const [partnerEmailValue, setPartnerEmailValue] = React.useState('');

	const [errorMsg, setErrorMsg] = React.useState('');

	const [validated, setValidated] = React.useState(false);
	const [submitted, setSubmitted] = React.useState(false);

	const [passMatch, setPassMatch] = useState(false);

	const [hitSubmit, setHitSubmit] = useState(false);

	const handleSubmit = (event) => {
		setHitSubmit(true);
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() === false) {
			console.log("Form did not work.")
			event.preventDefault();
			event.stopPropagation();
		} else {
			let { from } = { from: { pathname: "/" } };
			auth.confirmPasswordReset(partnerEmailValue, location.hash.slice(1), partnerPasswordValue, (result) => {
				console.log("Result here:")
				console.log(result)
				// If the password was successfully reset, nav to root
				if (result.success) {
					history.replace(from);
				} else {
					// If pwd reset failed, let the user know (stay on this page)
					event.preventDefault();
					event.stopPropagation();
					setErrorMsg(result.msg);
				}
			});
			setSubmitted(true);
		}
		setValidated(true);
	};

	const handleClick = () => {
		history.replace("login")
	};

	let errorMessage;

	if (passMatch === false && hitSubmit) {
		errorMessage = <p className={styles.errorText}>Something went wrong with the information that you entered. Please try again.</p>
	}

	return (
		<div className={styles.container}>
			{auth.user ? <Redirect
				to={{
					pathname: "/",
					state: { from: location }
				}}
			/>
				:
				<div className={styles.mainContainer}>
					<div className={styles.inputContainer}>
						<h1 className={styles.header}>MyBalance password reset</h1>
						<form className={styles.formContainer} noValidate validated={validated} onSubmit={handleSubmit}>
							<input
								className={styles.emailInput}
								type="text"
								placeholder="email"
								value={partnerEmailValue}
								required
								onChange={(event) => setPartnerEmailValue(event.target.value)}
							/>
							<input
								className={styles.passwordInput}
								type="password"
								placeholder="new password"
								value={partnerPasswordValue}
								required
								onChange={(event) => {
									setPartnerPasswordValue(event.target.value);
									if ((event.target.value === partnerPasswordConfirmValue) && (event.target.value !== "")) {
										setPassMatch(true)
									} else {
										setPassMatch(false);
									} 
								}}
							/>
							<input
								className={styles.passwordInput}
								type="password"
								placeholder="confirm new password"
								value={partnerPasswordConfirmValue}
								required
								onChange={(event) => {
									setPartnerPasswordConfirmValue(event.target.value);
									if ((partnerPasswordValue === event.target.value) && (event.target.value !== "")) {
										setPassMatch(true)
									} else {
										setPassMatch(false);
									}
								}}
							/> 
							{errorMessage}
							<input
								className={styles.submitButton}
								type="submit"
								value="Reset password"
							/>
						</form>
						<p className={styles.returnText} onClick={handleClick}>Return to login page</p>
					</div>
					<div className={styles.imageContainer}>

					</div>
				</div>
			}
		</div>
	)
}

// Change the focus state of the buttons.
// Check the image 