import React, { useEffect, useState } from 'react';
import { useAuth } from "../utils/use-auth";
import { Line } from "react-chartjs-2";
import styles from "../styles/home.module.css";
import { getDashboardData } from '../utils/api';
import { FiClipboard, FiLogOut, FiMenu, FiX } from "react-icons/fi";

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	BarElement,
	ArcElement,
} from 'chart.js';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	BarElement,
	ArcElement,
	Title,
	Tooltip,
	Legend
);

export default function HomePage(props) {
	const [chartData, setChartData] = useState(false);
	const [userCount, setUserCount] = useState(0);
	const [userCheckins, setUserCheckins] = useState(0);
	const [totalUserFootprint, setTotalUserFootprint] = useState(0);
	const [menuOpen, setMenuOpen] = useState(false);

	let auth = useAuth();

	useEffect(() => {
		getDashboardData(result => {
			// console.log(result);
			var checkinsByDataLinkId = {};
			var checkinsByMonth = {};
			const now = new Date();
			result.data.forEach(item => {
				const itemTimestamp = new Date(item.timestamp);
				// Remove any entries older than a year
				if (itemTimestamp > (new Date((now.getTime() - (1000 * 60 * 60 * 24 * 365))))) {
					// Populate checkins by data link id
					if (checkinsByDataLinkId[item.data_link_id] === undefined) {
						checkinsByDataLinkId[item.data_link_id] = [item];
					} else {
						checkinsByDataLinkId[item.data_link_id].push(item);
					}

					// Populate checkins by momnth
					// Only includes one (1) checkin per data_link_id per month
					if (checkinsByMonth[itemTimestamp.getMonth()] === undefined) {
						checkinsByMonth[itemTimestamp.getMonth()] = {};
						checkinsByMonth[itemTimestamp.getMonth()][item.data_link_id] = item;
					} else if (checkinsByMonth[itemTimestamp.getMonth()][item.data_link_id] === undefined) {
						checkinsByMonth[itemTimestamp.getMonth()][item.data_link_id] = item;
					} else {
						// Do date check to ensure that the item being inserted is the closest to the end of the month
						if (itemTimestamp > new Date(checkinsByMonth[itemTimestamp.getMonth()][item.data_link_id])) {
							checkinsByMonth[itemTimestamp.getMonth()][item.data_link_id] = item;
						}
					}
				}
			})

			// Get total projected footprint
			var totalFootprint = 0;
			Object.keys(checkinsByDataLinkId).forEach(key => {
				totalFootprint += checkinsByDataLinkId[key][checkinsByDataLinkId[key].length - 1].result;

			})
			setTotalUserFootprint(totalFootprint.toFixed(0));
			setUserCheckins(result.data.length);
			const recent_checkins = [...new Set(result.data.map(item => item.data_link_id))];
			setUserCount(recent_checkins.length);

			// Format chart data
			var formattedLabels = ["Jan", "Feb", "March", "April", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
			var formattedData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
			// Format checkinsByMonth
			var checkinsByMonthAsArray = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
			checkinsByMonthAsArray.forEach((val, index) => {
				if (checkinsByMonth[index] !== undefined) {
					checkinsByMonthAsArray[index] = checkinsByMonth[index];
				}
			})
			// Shift checkins by month array so that current month is on the right
			for (let i = 0; i <= now.getMonth(); i++) {
				checkinsByMonthAsArray.push(checkinsByMonthAsArray.shift());
				// Rearrange formatted labels by the same method
				formattedLabels.push(formattedLabels.shift());
			}
			// Go through each month, if key exists in previous month but not
			// current, copy value from previous month
			for (let i = 1; i < checkinsByMonthAsArray.length; i++) {
				// Filter previous month to find any data_link_id's that exist there but not in current month
				Object.values(checkinsByMonthAsArray[i - 1]).filter(checkin => !Object.keys(checkinsByMonthAsArray[i]).includes(checkin.data_link_id)).forEach((checkin, index) => {
					checkinsByMonthAsArray[i][checkin.data_link_id] = checkin;
				})
			}

			// Go through each month, condense to a per month per person average footprint
			for (let i = 0; i < checkinsByMonthAsArray.length; i++) {
				if (Object.keys(checkinsByMonthAsArray[i]).length > 0) {
					formattedData[i] = Object.values(checkinsByMonthAsArray[i]).map(val => (parseFloat(val.result) / Object.values(checkinsByMonthAsArray[i]).length)).reduce((a, b) => a + b);
				}
			}

			// Format data as per chart specs
			setChartData({
				labels: formattedLabels,
				datasets: [{
					label: 'Average footprint per month',
					data: formattedData,
					fill: false,
					borderColor: "#007C7A",
					tension: 0.2,
				}]
			});
		});


	}, []);

	const config = {
		type: 'line',
		data: chartData,
	};

	const handleSignOut = () => {
		console.log("You clicked the sign out button.");
		auth.logout((data) => {
			console.log(data);
		});
	}

	const menu = {
		open: `${styles.open}`,
		closed: `${styles.closed}` 
	}


	const handleClick = () => {
		setMenuOpen(!menuOpen);
	};

	const date = new Date();

	return (
		<div className={styles.container}>
			{/* Render nav or hamburger based on screen width */}
			<nav className={styles.nav}>
				<div className={styles.menuIconContainer} onClick={handleClick}>
					<FiMenu className={styles.menuIcon} />
				</div>
				<p className={styles.date}>{`${date.toLocaleString("default", { day: "numeric", month: "long", weekday: "long", year: "numeric" })}`}</p>
				<div className={styles.profileContainer}>
					<p className={styles.profileText}>Hello, {auth.user.email}</p>
				</div>
			</nav>
			<div className={`${styles.sidebar} ${menuOpen ? menu.open : menu.closed}`}>
				<div className={styles.closeMenuIconContainer} onClick={handleClick}>
					<FiX className={`${styles.menuIcon} ${styles.closeMenuIcon}`} />
				</div>
				<p className={styles.sidebarHeading}>MyBalance</p>
				<div className={styles.sidebarItemContainer}>
					<FiClipboard className={styles.sidebarIcon} />
					<p className={styles.sidebarItem}>Dashboard</p>
				</div>
				<div className={`${styles.sidebarItemContainer} ${styles.sidebarLogout}`} onClick={handleSignOut}>
					<FiLogOut className={styles.sidebarIcon} />
					<p className={styles.sidebarItem}>Log out</p>
				</div>
			</div>
			{chartData ?
				<>
					<div className={styles.mainDataSection}>
						<h1 className={styles.mainHeading}>Dashboard</h1>
						<div className={styles.dataRow}>
							<div className={styles.totalUsersContainer}>
								<p className={styles.totalUsersText}>App downloads</p>
								<p className={styles.totalUsersActual}>{userCount}</p>
							</div>
							<div className={styles.totalUserFootprintContainer}>
								<p className={styles.totalUserFootprintText}>Total annual projected footprint</p>
								<p className={styles.totalUserFootprintActual}>{totalUserFootprint} tonnes</p>
							</div>
							<div className={styles.checkinsContainer}>
								<p className={styles.checkinsText}>Checkins</p>
								<p className={styles.checkinsActual}>{userCheckins}</p>
							</div>
						</div>
						<div className={styles.dataContainerLine}>
							<Line
								data={chartData}
								options={config}
							/>
						</div>
						{/* <div className={styles.textRow}>
							<p>The graph above displays the average monthly footprint of all of your users. It represents how your tenants and customers are changing their behaviour and gradually lowering their carbon footprint.</p>
						</div> */}

						{/* Div-to-div highlight? */}
						{/* theme on vs code  */}
						{/* Logout-refresh bug */}
						{/* CSS grid for responsive? */}

						{/* <div className={styles.dataRowTwo}>
							<div className={styles.dataContainerDoughnut}>
								<Doughnut
									data={doughnutData}
								/>
							</div>
							<div className={styles.dataContainerDoughnut}>
								<Doughnut
									data={doughnutDataTwo}
								/>
							</div>
						</div>
						<div className={styles.textRow}>
							<p>This doughnut chart displays how often your users log on to the app. Actives users of the product use it twice a week or more, rare users of the produce use it once or twice a month, and the rest have not yet engaged with the product.</p>
						</div> */}
					</div>
				</>
				:
				<div>
					<p>
						Data loading.
						{/* Bring in the Spinner component here. */}
					</p>
				</div>
			}
		</div>
	)
}