// utils/use-auth.js
// Authentication context providers and associated methods.
import React, { useState, useEffect, useContext, createContext } from "react";
import { Route, Redirect } from 'react-router-dom';

import {
    LOGIN_URL,
    LOGOUT_URL,
    PWD_RESET_TRIGGER_URL,
    PWD_RESET_CONFIRM_URL,
    CHECK_TOKEN_URL,
} from '../config';

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Auth method for logging in a partner
    const login = async (email, password, callback) => {
        // Make request to login
        let response = await fetch(LOGIN_URL, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                email: email,
                pwd: password,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            // Check that login was a success
            let json = await response.json();
            if (json.success) {
                // Set auth provider user
                setUser(json.partner);
                // Callback OK
                callback({ success: true, msg: "" })
            } else {
                callback({ success: false, msg: "Login failed" })
            }
        } else {
            // Callback login failure
            callback({ success: false, msg: "Login failed" })
        }
    };

    // Auth method for logging a partner out
    const logout = async (callback) => {
        // Make request to clear cookie
        let response = await fetch(LOGOUT_URL, {
            credentials: 'include',
            method: 'POST',
        });

        if (response.ok) {
            // Clear user from auth provider state.
            setUser(false);
            // Callback OK
            callback({ success: true, msg: "" })
        } else {
            // Callback logout failure
            callback({ success: false, msg: "Logout failed" })
        }
    };

    const sendPasswordResetEmail = async (email, callback) => {
        // Make request to confirm password reset
        let response = await fetch(PWD_RESET_TRIGGER_URL, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                email: email,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            // Check that reset was a success
            let json = await response.json();
            if (json.success) {
                // Callback OK
                callback({ success: true, msg: "" })
            } else {
                callback({ success: false, msg: "Password reset trigger failed" })
            }
        } else {
            // Callback reset failure
            callback({ success: false, msg: "Password reset trigger failed" })
        }
        callback();
    };

    const confirmPasswordReset = async (partner_email, ver_code, new_password, callback) => {
        // Make request to confirm password reset
        let response = await fetch(PWD_RESET_CONFIRM_URL, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                email: partner_email,
                new_pwd: new_password,
                code: ver_code,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            // Check that reset was a success
            let json = await response.json();
            if (json.success) {
                // Set auth provider user
                setUser(json.partner);
                // Callback OK
                callback({ success: true, msg: "" })
            } else {
                callback({ success: false, msg: "Password reset failed" })
            }
        } else {
            // Callback reset failure
            callback({ success: false, msg: "Password reset failed" })
        }
    };

    useEffect(() => {
        // Make request to check token. Must be wrapped in own func due to
        // non-async requirements for useEffect itself
        const checkToken = async () => {
            setIsLoading(true);
            let response = await fetch(CHECK_TOKEN_URL, {
                credentials: 'include',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                // Check that reset was a success
                let json = await response.json();
                if (json.success) {
                    // Callback OK
                    setUser(json.partner);
                    setIsLoading(false);
                } else {
                    setUser(false);
                    setIsLoading(false);
                }
            } else {
                // Callback checktoken failure
                setUser(false);
                setIsLoading(false);
            }
        }

        checkToken();

    }, [])

    // Return the user object and auth methods
    return {
        user,
        login,
        logout,
        sendPasswordResetEmail,
        confirmPasswordReset,
        isLoading
    };
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export function PrivateRoute({ children, ...rest }) {
    let auth = useAuth();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth.user ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}