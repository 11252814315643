import { GET_DASHBOARD_DATA_URL } from "../config";

export const getDashboardData = async callback => {
    let response = await fetch(GET_DASHBOARD_DATA_URL, {
        credentials: 'include',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        let json = await response.json();
        if (json.success) {
            // Callback OK
            callback({ success: true, data: json.data })
        } else {
            callback({ success: false, msg: "Get dashboard data failed" })
        }
    } else {
        // Callback reset failure
        callback({ success: false, msg: "Get dashboard data failed" })
    }
}