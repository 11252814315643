import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Spinner from "./components/spinner";
import { HomePage, LoginPage, PasswordResetPage, ForgotPasswordPage } from "./pages";
import { ProvideAuth, PrivateRoute, useAuth } from "./utils/use-auth";

const Navigator = () => {
	const auth = useAuth();

	return (
		<Router>
			{
				auth.isLoading ?
					 <Spinner />
					:
					<div>
						<Switch>
							<Route path="/pwdreset">
								<PasswordResetPage />
							</Route>
							<Route path="/login">
								<LoginPage />
							</Route>
							<Route path="/forgotpassword">
								<ForgotPasswordPage />
							</Route>
							<PrivateRoute path="/">
								<HomePage />
							</PrivateRoute>
							{/* <PrivateRoute path="/dashboardsettings">
								<HomePage />
							</PrivateRoute> */}
						</Switch>
					</div>
			}
		</Router>
	)
};

// This example has 3 pages: a public page, a protected
// page, and a login screen. In order to see the protected
// page, you must first login. Pretty standard stuff.
//
// First, visit the public page. Then, visit the protected
// page. You're not yet logged in, so you are redirected
// to the login page. After you login, you are redirected
// back to the protected page.
//
// Notice the URL change each time. If you click the back
// button at this point, would you expect to go back to the
// login page? No! You're already logged in. Try it out,
// and you'll see you go back to the page you visited
// just *before* logging in, the public page.

export default function AuthExample() {
	return (
		<ProvideAuth>
			<Navigator />
		</ProvideAuth>
	);
}
